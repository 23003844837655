.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
padding-top: 20px;
    .custom-form {
        width: 100%;
        // max-width: 600px;
        background: #fff;
        
        padding: 20px;
        // border: 1px solid #dfe1e6;
        // border-radius: 8px;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

        .form-row {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            .form-group {
                flex: 1;
            }
        }

        .form-group {
            margin-bottom: 16px;

            label {
                display: block;
                margin-bottom: 8px;
                font-weight: 600;
                color: #000;

                span {
                    color: #d32f2f;
                }
            }

            input,
            select {
                width: 100%;
                padding: 10px;
                font-size: 14px;
                border: 1px solid #cfd2d6;
                border-radius: 4px;
                outline: none;
                transition: border-color 0.2s ease;

                &:focus {
                    border-color: #0052cc;
                }
            }

            .error-text {
                margin-top: 4px;
                font-size: 12px;
                color: #d32f2f;
            }
        }

        .form-actions {
            text-align: right;


        }
    }
}

.radius-12{
    border-radius: 12px;
}