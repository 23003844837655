@font-face {
    font-family: "D-DIN";
    src: url("./fonts/D-DIN.otf") format("opentype");
    font-weight: normal; /* Adjust if your font has different weights */
    font-style: normal; /* Adjust if your font has different styles */
}
@font-face {
    font-family: "D-DINCondensed";
    src: url("./fonts/D-DINCondensed.otf") format("opentype");
    font-weight: normal; /* Adjust if your font has different weights */
    font-style: normal; /* Adjust if your font has different styles */
}

@font-face {
    font-family: "D-DIN";
    src: url("./fonts/D-DIN-Bold.otf") format("opentype");
    font-weight: bold; /* Adjust if your font has different weights */
    font-style: normal; /* Adjust if your font has different styles */
}
@font-face {
    font-family: "D-DINCondensed";
    src: url("./fonts/D-DINCondensed-Bold.otf") format("opentype");
    font-weight: bold; /* Adjust if your font has different weights */
    font-style: normal; /* Adjust if your font has different styles */
}
@font-face {
    font-family: "D-DIN";
    src: url("./fonts/D-DIN-Italic.otf") format("opentype");
    font-weight: normal; /* Adjust if your font has different weights */
    font-style: italic; /* Adjust if your font has different styles */
}

@import "./bootstrap.scss";

@import "./fonts.scss";

body {
    font-family: "D-DIN", sans-serif;
    margin: 0 !important;
    background-color: #000;
}
html {
    scroll-behavior: smooth;
}
@import "./card-designs.scss";

.text-right {
    text-align: right;
}

@import "./forms.scss";

@import "./section-designs.scss";

.font-underline {
    text-decoration: underline;
}

@import "./background-colors.scss";

@media(min-width: 992px){
    .sticky-column {
        position: sticky;
        top: 0;
        height: 140vh; // Ensures it covers the full viewport height
        overflow-y: auto; // A
    }
}

