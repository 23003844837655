@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --background: #000000;
  --foreground: #ededed;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #000000;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
}
