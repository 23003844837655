.products-overview-card {
    background-color: #000;
    border: 1px solid #67646475;
    color: #fff;
    position: relative;
    box-shadow:
      0 4px 6px rgba(255, 255, 255, 0.1),
      0 1px 3px rgba(255, 255, 255, 0.1); /* Light white shadow */
    border-radius: 12px;
    overflow: hidden;
    padding: 24px;
    transition: all 0.3s ease-out;
    height: 100%;
    &:hover {
      background-color: #fff;
      color: #000;
      box-shadow:
        0 4px 6px rgba(255, 255, 255, 0.3),
        0 1px 3px rgba(255, 255, 255, 0.3);
      padding: 12px;
    }
  
    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
      border-radius: 12px;
    }
  }
  
  .products-overview-card.active {
    background-color: #fff;
    color: #000;
    box-shadow:
      0 4px 6px rgba(255, 255, 255, 0.3),
      0 1px 3px rgba(255, 255, 255, 0.3);
    padding: 12px;
  }
  .tech-overview-card {
    background-color: #000;
    border: 1px solid #67646475;
    height: 100%;
    width: 100%;
    color: #fff;
    position: relative;
    box-shadow:
      0 4px 6px rgba(255, 255, 255, 0.1),
      0 1px 3px rgba(255, 255, 255, 0.1); /* Light white shadow */
    border-radius: 12px;
    overflow: hidden;
    padding: 24px;
    transition: all 0.3s ease-out;
  
    &:hover {
      background-color: #fff;
      color: #000;
      box-shadow:
        0 4px 6px rgba(255, 255, 255, 0.3),
        0 1px 3px rgba(255, 255, 255, 0.3);
      padding: 12px;
    }
  
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .img-section {
      height: 300px;
    }
  }
  .tech-overview-card.active {
    background-color: #fff;
    color: #000;
    box-shadow:
      0 4px 6px rgba(255, 255, 255, 0.3),
      0 1px 3px rgba(255, 255, 255, 0.3);
    padding: 12px;
  }
  
  .mega-item-card {
    background-color: #000;
    border: 1px solid #67646475;
    color: #fff;
    position: relative;
    box-shadow:
      0 4px 6px rgba(255, 255, 255, 0.1),
      0 1px 3px rgba(255, 255, 255, 0.1); /* Light white shadow */
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease-out;
    height: 100%;
  
    background-color: #fff;
      color: #000;
      box-shadow:
        0 4px 6px rgba(255, 255, 255, 0.3),
        0 1px 3px rgba(255, 255, 255, 0.3);
      padding: 12px;
      overflow: hidden;
    &:hover {
      background-color: #fff;
      color: #000;
      box-shadow:
        0 4px 6px rgba(255, 255, 255, 0.3),
        0 1px 3px rgba(255, 255, 255, 0.3);
      padding: 12px;
      img{
        scale: 110%;
      }
    }
  
    img {
      width: 100%;
      height: 160px;
      object-fit: contain;
      border-radius: 12px;
  
      scale: 95%;
  
      transition: all .2s ease-in-out;
    }
  }
  
  