.home-hero {
  display: flex;
  align-items: center;
  background-image: url("https://myelinz.com/home/hero-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
iframe.bg-primary{
  background-color: #000 !important;
  #canvas{
    background-color: #000 !important;
  }
}
.featured-news-card {
  img {
    width: 100%;
    height: 340px;
    object-fit: cover;
    border-radius: 12px;
  }
}
.leadership-member {
  height: 100%; 
  p {
      text-align: start;
      padding: 0.5rem 0rem;
  }
  h5 {
      text-align: start;
      font-weight: bold;
      margin-bottom: 2rem;
  }
  img{
      width: 100%;
      height: 370px;
      object-fit: cover;
  }
}
.home-page {
 

  .why-collaboration-card {
    background-color: #000;
    border: 1px solid #67646475;
    height: 100%;
    width: 100%;
    color: #fff;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1),
      0 1px 3px rgba(255, 255, 255, 0.1); /* Light white shadow */
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: #fff;
      color: #000;
      box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3),
        0 1px 3px rgba(255, 255, 255, 0.3);
    }
  }

  // Top Sentence
  .top-sentence {
    background-color: white;
    text-align: center;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .top-sentence p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }

  .top-sentence-link {
    color: black;
    text-decoration: none;
    font-weight: bold;
    padding-left: 1rem;
  }

  .top-sentence-link:hover {
    text-decoration: underline;
  }

  // Call to Innovation

  .video-full-width {
    width: 100%;
    height: 70vh;
    border: none;
  }

  @media (max-width: 768px) {
    .video-full-width {
      height: 50vh;
    }
  }
}
