@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.banner {
  position: fixed;
  bottom: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #000;
  z-index: 1000;
  animation: slideUp 0.5s ease-out;
}

.bannerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 20px;
  width: 90%;
  margin: 0 auto;
}

.text {
  flex-basis: 60%;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 16px;
  margin: 0;
  line-height: 1.6;
}

.link {
  color: #00f;
  text-decoration: underline;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.buttonPrimary {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
}

.buttonSecondary {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.closeButton {
  background-color: transparent;
  color: #000;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}
