.insight-card {
    color: #000;
    transition: all 0.2s ease-in-out;
    border-top: 1px solid #000;
    &:last-child {
        border-bottom: 1px solid #000;
    }
    .title {
        font-size: 28px;
        line-height: 28px;
        font-weight: bold;

        transform: translateX(-20px);

        transition: all 0.3s ease-in-out;
    }

    &:hover {
        background-color: #000;
        color: #fff;

        .title {
            transform: translateX(0px);
        }
    }
}

@media (min-width: 768px) {
    .insight-card {
        .title {
            font-size: 46px;
            line-height: 46px;
        }
    }
}


.blog-content{
    
}