.hero-title{
    font-size: 56px;
    line-height: 60px;
}
.section-title{
    font-size: 46px;
    line-height: 52px;
}

.font-18{
    font-size: 18px;
}
.font-16{
    font-size: 16px;
}
p{
    font-size: 20;
}

.blog-content {
    h2 {
        font-size: 1.5rem;
        line-height: 1.5 rem;
    }
}

@media (min-width: 768px) {
    .hero-title{
        font-size: 90px;
        line-height: 100px;
    }
    .section-title{
        font-size: 56px;
    }
    .font-36{
        font-size: 36px;
        line-height: 36px;
    }
    .blog-content {
        h2 {
            font-size: 2.2rem;
        }
    }
}
.font-22{
    font-size: 22px;
}
.font-26{
    font-size: 26px;
}
.font-14{
    font-size: 14px !important;
}

.font-36{
font-size: 28px;
line-height: 32px;
}
.font-32{
    font-size: 32px;
}

.border-top{
    border-top: 1px solid #9898985c;
}
.font-underline{
    text-decoration: underline;
}

.text-proper{
    text-transform: capitalize;
}