$white: #FFF;
$gray-300: #7f7979;
$gray-700: #444;
$gray-900: #222;
$brand-primary: #fff;

.steps {
  margin: 0 auto;
  padding: 1rem;
  text-align: left;

  &-list {
    padding-left: 0;
    margin: 30px auto 0;
    display: flex;
    
    &-item {
      display: inline-block;
      margin: 0;
      color: $gray-700;
      position: relative;
      margin-top: 10px;
      text-align: center;
      flex: 0 1 100%;
      justify-content: center;
      
      span {
        position: absolute;
        top: -30px;
        width: 100%;
        height: 20px;
        left: 0;
        right: 0;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 47%;
          display: block;
          width: 5px;
          height: 5px;
          line-height: 1px;
          border: 10px solid ;
          border-radius: 10px;
          border-color: $gray-300;
          background-color: $gray-300;
          color: $white;
          z-index: 1;
        }
        &:after {
          border-top: 4px solid $gray-300;
          content: "";
          right: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) ;
          display: inline-block;
          width: 100%;
          // z-index: 1;
        }
      }

      &:first-child span:after {
        display: none;
      }

      &.completed {
        color: $white;
        span {
          &:after,
          &:before {
            border-color: $brand-primary;
            background-color: $brand-primary;
          }
        }
      }
      
    }
  }
}

@media (max-width: 768px) {
  .f-card{

    width: 260px !important;
  }
.steps{
  overflow-x: scroll;
width: 100%;
  &-list {
    padding-left: 0;
    margin: 30px auto 0;
    display: flex;
    width: 400px !important;  
    &-item {
      display: inline-block;
      margin: 0;
      color: $gray-700;
      position: relative;
      margin-top: 10px;
      text-align: center;
      flex: 0 1 100%;
      justify-content: center;
      width: 400px !important;
      span {
        position: absolute;
        top: -30px;
        width: 100%;
        height: 20px;
        left: 0;
        right: 0;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 47%;
          display: block;
          width: 5px;
          height: 5px;
          line-height: 1px;
          border: 10px solid ;
          border-radius: 10px;
          border-color: $gray-300;
          background-color: $gray-300;
          color: $white;
          z-index: 1;
        }
        &:after {
          border-top: 4px solid $gray-300;
          content: "";
          right: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) ;
          display: inline-block;
          width: 100%;
          // z-index: 1;
        }
      }

      &:first-child span:after {
        display: none;
      }

      &.completed {
        color: $white;
        span {
          &:after,
          &:before {
            border-color: $brand-primary;
            background-color: $brand-primary;
          }
        }
      }
      
    }
  }
}
} 

