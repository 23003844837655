.swiper-btn-next {
    color: #fff !important;

    &::after {
        color: #fff !important;
    }
}
.swiper-btn-pref {
    color: #fff !important;

    &::after {
        color: #fff !important;
    }
}
.swiper-btn-disabled {
    color: #fff !important;

    &::after {
        color: #fff !important;
    }
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    color: #fff;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    color: #fff;
}

.swiper-pagination-bullet, .swiper-pagination-bullet-active{
    color: #fff;
}