.news-card {
  position: relative;
  height: 100%;
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 12px;
  }

  .read-more{
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.featured-news-card {
  img {
    width: 100%;
    height: 340px;
    object-fit: cover;
    border-radius: 12px;
  }
}
.category-button {
  .btn {
    min-width: 200px;
  }
}

@media (max-width: 768px) {
  .category-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 1rem;

    .btn {
      min-width: calc(50%);
      margin: 0;
    }
  }
}

@media (max-width: 576px) {
  .category-button {
    .btn {
      min-width: 100%;
    }
  }
}
