#mega-menu-full-cta-dropdown {
    position: absolute;
    left: 0;
    top: 42px;
    width: 100%;
    padding-top: 8px;
    // max-width: 100vw;
    display: none;
    transition: all 1s ease-in-out;

   .card{
    overflow: hidden;

    .product-img{
    scale: 100%;
    transition: all .2s ease-in-out;

    }
    &:hover{
        .product-img{
            scale: 130%;
        }
    }
   }
}
.position-relative {
    position: relative;
}

.products-nav {
    &:hover {
        #mega-menu-full-cta-dropdown {
            display: block;
        }
    }
}

.mega-menu-text {
    border-left: 1px solid black;
    // height: 100%;
    text-wrap: wrap;
}
.h-100{
    height: 100%;
}

.mega-item-card{
    p{
        text-wrap: wrap;
    }
}