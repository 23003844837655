$theme-colors: (
  "primary": #000000,
  "secondary": #666666,
  "white": #ffffff,
  "light": #a6a6a6
);

$theme-blue: #1877f2;
// $theme-colors: (
//     "primary": #51796F,
//     "danger": #aa4465,
// );

// @import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";

// @import "./reset.scss";

// uncomment later
// @media (min-width: 768px) {
//   .container {
//     max-width: 750px;
//   }
// }
// @media (min-width: 992px) {
//   .container {
//     max-width: 970px;
//   }
// }
// @media (min-width: 1300px) {
//   .container {
//     max-width: 1210px;
//   }
// }
// @media (min-width: 1650px) {
//   .container {
//     max-width: 1400px;
//   }
// }

.btn {
  padding: 0.75rem 2rem !important;
  border-radius: 0;
}
.btn-small {
  padding: 0.75rem 2rem !important;
  border-radius: 0;
  font-size: 18px;
}
@media (max-width: 768px) {
  .btn-small {
    padding: 0.45rem 1rem !important;
    border-radius: 0;
    font-size: 15px;
  }
}
.btn.btn-blue {
  background-color: #1877f2 !important;
  color: #fff;
  border: 2px solid #1877f2;
  transition: all 0.3s ease-in-out;


  &:hover {
      background-color: #000000 !important;
      border: 2px solid white;
      color: #fff;
  }
}

.link-white{
  color: #fff;
  transition: all .2s ease-in-out;

  &:hover{
    color: #1877f2;
    text-decoration: underline;
    opacity: 1 !important;

  }
}
.link-blue{
  color: #1877f2;
  transition: all .2s ease-in-out;

  &:hover{
    color: #1877f2;
    text-decoration: underline;
  }
}