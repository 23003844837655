.pub-card{
    border-top: 1px solid #c7c7c779;

    .icons{
        font-size: 36px;
        display: flex;
        color: grey;
        transition: all .3s ease-in;

        &:hover{
            color: #fff;
        }
    }
}
.pub-card:last-child{
    border-bottom: 1px solid #c7c7c779;

}