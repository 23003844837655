.hero-section {
  background-image: url("./career-hero.jpg");
  background-size: cover;
  background-position: center;
  height: 70vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  position: relative;
.content{
  z-index: 10;
}
  .overlay{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 5;
    filter: blur(1px);
  }
}


.hero-heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: white;
}

.hero-description {
  font-size: 1.2rem;
  max-width: 800px;
}

.difference-section {
  background: white;
  color: black;
}

.difference-content {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.difference-image-container img {
  width: 100%;
  height: 600px;
}

.life-section {
  background: black;
  color: white;
  padding: 5rem 0rem;
}

.life-content {
  text-align: center;
}

.life-image-container img {
  width: 100%;
  height: 600px;
}

.inclusive-section {
  background: black;
  color: white;
  padding: 5rem 0rem;
}

.inclusive-content {
  text-align: center;
}

.inclusive-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.learn-more-btn {
  background: black;
  color: white;
  border: 1px solid white;
}

.inclusive-text-container {
  width: 70%;
}

.inclusive-text-container p {
  text-align: end;
}

.inclusive-image-container img {
  width: 100%;
  height: 600px;
}

.gallery-section {
  background: black;
  color: white;
  padding: 5rem 0rem;
}

.gallery-content {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.gallery-row img {
  width: 100%;
}

.student-opportunities-section {
  background-image: url("./student-opportunity.avif");
  background-size: cover;
  background-position: top;
  height: 90vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  position: relative;
  .overlay{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 5;
    filter: blur(1px);
  }
  .content{
    z-index: 10;
    p{
      line-height: 24px;
      font-size: 18px;
    }
  }
}


@media (max-width: 768px) {
  .inclusive-flex {
    flex-direction: column;
    align-items: center;
  }
  .inclusive-content {
    h1 {
      text-align: center;
      padding-bottom: 1rem;
    }
  }
  .gallery-content {
    h1 {
      margin-bottom: 1rem;
    }
  }
  .inclusive-text-container {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  .learn-more-btn {
    width: 100%;
    margin-bottom: 1rem;
  }

  .inclusive-text-container p {
    text-align: center;
  }


  .apply-now-btn {
    width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 576px) {
  .inclusive-flex {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inclusive-content {
    h1 {
      text-align: center;
      padding-bottom: 1rem;
    }
  }
  .inclusive-text-container {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  .learn-more-btn {
    width: 100%;
    margin-bottom: 1rem;
  }

  .inclusive-text-container p {
    font-size: 1rem;
    text-align: center;
  }
  .gallery-content {
    h1 {
      margin-bottom: 1rem;
    }
  }
  .apply-now-btn {
    width: 100%;
    padding: 1rem 2rem;
  }
}
