.btn {
    padding: 0.55rem 2rem !important;
    border-radius: 0;
}
.btn.btn-theme {
    background-color: #1877f2 !important;
    color: #fff;
    border: 2px solid #1877f2;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: transparent;
        border: 2px solid white;
        color: #fff;
    }
}

.img-bw {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: grayscale(50%);
    }
}

.text-upper {
    text-transform: uppercase;
}

.border-bottom-light {
    border-bottom: 1px solid #42404088;
}
.border-top-light {
    border-top: 1px solid #42404088;
}

.blog-content {
    h2 {
        font-size: 2.2rem;
    }
}
.bg-white{
    color: #000;
}
.btn-white {
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    overflow: hidden;
    display: inline-block;
    transition: color 0.3s ease !important;
    z-index: 1;
    position: relative;

}
.btn-white::before{
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
    transition: top 0.3s ease-in-out;
}


.btn-white:hover {
    color: #000 !important;
}

.btn-white:hover::before {
    top: 0;
}
// .btn-primary {
//   border: 2px solid #000;
//   color: #000;
//   background-color: #fff;
//   padding: 0.75rem 2rem !important;
//   border-radius: 0;
//   transition: all 0.3s ease;
//   &:hover {
//     background-color: #000;
//     color: #fff;
//   }
// }
.btn-primary {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: 2px solid #000;
    color: #000 !important;
    background-color: #fff;
    padding: 0.55rem 2rem !important;
    border-radius: 0;
    transition: color 0.3s ease !important;
    z-index: 1;
}

.btn-primary::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: -1;
    transition: top 0.3s ease-in-out;
}

.btn-primary:hover {
    color: #fff !important;
}
.btn-primary.active{
    color: #fff !important;
}
.btn-primary.active::before{
    top: 0;
}

.btn-primary:hover::before {
    top: 0;
}

// dfghjkdfghjkldcfvghjmk



.text-link {
    text-decoration: underline;
    transition: all 0.3s ease-in-out;

    &:hover {
        font-weight: bold;
    }
}
