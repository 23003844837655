.hero-section {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    text-align: center;
    .hero-content {
        z-index: 10;
    }
    .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.7;
        z-index: 5;
        filter: blur(1px);
    }
}
.home-hero {
    height: 80vh;
    display: flex;
    align-items: center;
    background-image: url("https://myelinz.com/home/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-text {
    max-width: 600px;
    margin: 0 auto 30px;
}

.stat-div {
    position: absolute;
    bottom: 50px;
    width: 80%;
}

.stat-div .row {
    justify-content: center;
}

.stat-div .col-md-4 {
    text-align: center;
}

.mission-section,
.vision-section,
.video-section {
    .section-title {
        margin-bottom: 1rem;
    }

    .section-text {
        max-width: 1200px;
        margin: 0 auto 2rem;
        font-size: 1.125rem;
        line-height: 1.6;
    }

    .image-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        img {
            max-width: 100%;
            //   border-radius: 10px;
        }
    }
}

.video-section {
    iframe.video-full-width {
        width: 100%;
        height: 500px;
    }
}
.modal-img {
    height: 320px;
    width: auto;
    object-fit: contain;
}
.hero-image {
    width: 70%;
    margin: 0 auto;
    height: 400px;
    margin-top: 3rem;
    margin-bottom: 9rem;
}

.leadership-text {
    width: 50%;
}
.leadership-member {
    height: 100%;
    position: relative;
    p {
        text-align: start;
        padding: 0.5rem 0rem;
    }
    h5 {
        text-align: start;
        font-weight: bold;
        margin-bottom: 2rem;
    }
    img {
        width: 100%;
        height: 370px;
        object-fit: cover;
    }
    .btn-space{
        height: 30px;
        width: 100%;
    }
    .meet-btn{
        position: absolute;
        bottom: 10px;
        left: 0;
    }
}
.team-member-button {
    align-items: left;
    padding: 10px 40px;
}

// Awards----------------------------
.awards-section {
    padding: 3rem 1rem;
    background-color: #f9f9f9;

    .section-title {
        margin-bottom: 2rem;
        color: black;
    }

    .photo-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1.5rem;
    }

    .photo-item {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background: #fff;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            transition: transform 0.3s ease;
        }

        &:hover img {
            transform: scale(1.1);
        }

        .photo-description {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            text-align: center;
            padding: 0.5rem;
            font-size: 0.9rem;
            opacity: 0;
            transform: translateY(100%);
            transition:
                opacity 0.3s ease,
                transform 0.3s ease;

            p {
                margin: 0;
            }
        }

        &:hover .photo-description {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@media (max-width: 768px) {
    .hero-image {
        width: 90%;
    }

    .leadership-text {
        width: 90%;
    }
    .leadership-section {
        h1 {
            font-size: 2rem;
        }
    }
}

@media (max-width: 576px) {
    .hero-image {
        width: 90%;
    }

    .leadership-text {
        width: 90%;
    }
    .leadership-section {
        h1 {
            // font-size: 2rem;
        }
    }
}
